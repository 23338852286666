import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ResponsiveAppBar(props) {
    return (
            <HideOnScroll {...props}>
            <AppBar>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingTop: 1, paddingBottom: 1 }}
                >

                    <Link to="/" >
                    <Stack direction="row" alignItems="center" >
                        <Button>
                            <img src="https://markokecman.com/mk-logo-1.png" style={{ width: '41px', height: '41px' }} alt="logo" />
                        </Button>
                        <Typography variant="h5">Marko Kecman Photography</Typography>
                    </Stack>
                    </Link>
                </Grid>
                </AppBar>
            </HideOnScroll>
    );
}