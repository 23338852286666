import React from 'react';

export default function LongAnswerView(props)
{
    return (
        <p style={{ whiteSpace: 'pre-wrap', maxWidth: '80%', marginLeft: '10%' }}>{props.text}</p>
    );
}


