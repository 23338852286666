import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Spacer from '../common/Spacer';

function Footer(props)
{
    const navigate = useNavigate();

    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <Spacer />
            {props.showButtons && (<Stack direction="row">
                <Button variant="text" onClick={() => window.scrollTo(0, 0)}>
                    <ArrowUpwardIcon />
                </Button>
                <Spacer />
                <a href="/">
                    <Button variant="text" >
                        <HomeIcon />
                    </Button>
                </a>
            </Stack>
            )}
            <Spacer />
            <Typography variant="caption">© Marko Kecman - All rights reserved</Typography>
            <Spacer />
        </Grid>
    );
}
export default Footer;