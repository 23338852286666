import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HeightIcon from '@mui/icons-material/Height';
import { Button, Stack, TextField } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteData, getDataAsync, updateData, writeData } from '../../app/firebase';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { withAuthentication } from '../login/withAuthentication';
import { getUser } from '../user/UserSlice';
import PageCopyDialog from './PageCopyDialog';
import { getCurrentProtocol, getProtocolCurrentPage, setCurrentProtocol, setProtocolCurrentPage } from './ProtocolSlice';

function AdminProtocolPages(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const protocol = useSelector(getCurrentProtocol);
    const protocol_page = useSelector(getProtocolCurrentPage);
    let page = null;
    if (protocol.pages !== undefined)
    {
        page = protocol.pages[protocol_page];
        console.log("current pageId: " + page.id);
    }
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [deletePageID, setDeletePageID] = useState(0);
    const [isCopyOpen, setIsCopyOpen] = useState(false);
    const [copyQuestion, setCopyQuestion] = useState({});

    useEffect(() =>
    {
        // cleanup function
        return () =>
        {
            console.log("clear AdminProtocolPages");
        };
    }, [protocol_page, protocol]);

    const selectPage = (pageIndex) =>
    {
        dispatch(setProtocolCurrentPage(pageIndex));
    }

    const onSetProperty = (property, data) =>
    {
        const newProtocol = cloneDeep(protocol);
        newProtocol.pages[protocol_page][property] = data;

        let valueObj = {};
        valueObj[property] = data;

        updateData('/protocols/' + protocol.id + '/pages/' + protocol_page, valueObj, () => dispatch(setCurrentProtocol(newProtocol)));
    }

    const onSetNewId = (id) =>
    {
        const newProtocol = cloneDeep(protocol);
        newProtocol.pages[protocol_page].id = id;

        let valueObj = {};
        valueObj.id = id;

        //update protocol definition
        updateData('/protocols/' + protocol.id + '/pages/' + protocol_page, valueObj, () =>
        {
            //load old protocol
            getDataAsync('/protocols_pages/' + protocol.pages[protocol_page].id, oldProtocol =>
            {
                //write new protocol
                writeData('/protocols_pages/' + id, oldProtocol, () =>
                {
                    //delete old protocol
                    deleteData('/protocols_pages/' + protocol.pages[protocol_page].id, () => dispatch(setCurrentProtocol(newProtocol)));
                })
            })
        });
    }

    const onPatientDeleteConfirm = (isConfirmed) =>
    {
        if (isConfirmed)
        {
            const newProtocol = cloneDeep(protocol);
            newProtocol.pages.splice(deletePageID, 1);
            for (let index = 0; index < newProtocol.pages.length; index++)
            {
                newProtocol.pages[index].index = index;
            }

            updateData('/protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
            {
                console.log('Page deleted');
                deleteData('/protocols_pages/' + protocol.pages[deletePageID].id, () => { dispatch(setCurrentProtocol(newProtocol)); dispatch(setProtocolCurrentPage(0)); });
            });
        }

        setIsDeleteOpen(false);
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex)
        {
            const newProtocol = cloneDeep(protocol);
            const item = newProtocol.pages.splice(fromIndex, 1)[0];
            newProtocol.pages.splice(toIndex, 0, item);
            for (let index = 0; index < newProtocol.pages.length; index++)
            {
                newProtocol.pages[index].index = index;
            }

            updateData('/protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
            {
                console.log('Page order changed');
                dispatch(setCurrentProtocol(newProtocol));
                dispatch(setProtocolCurrentPage(toIndex));
            });
        },
        nodeSelector: '.protocolPagesSelector',
        handleSelector: '.protocolPagesHandle',
    };

    const onAddNewPageClick = event =>
    {
        const newProtocol = cloneDeep(protocol);
        if (newProtocol.pages == undefined)
            newProtocol.pages = [];

        const newPage = { id: "NewGallery", name: "New Gallery", index: newProtocol.pages.length };
        newProtocol.pages.push(newPage);

        updateData('/protocols/' + newProtocol.id, { pages: newProtocol.pages }, () =>
        {
            console.log('Page added');
            writeData('/protocols_pages/' + newPage.id,
                [{
                    "answers": [
                        {
                            "answer": "",
                            "index": 0
                        }
                    ],
                    "index": 0,
                    "question": "New Element",
                    "text": "Gallery Name",
                    "type": "shortanswer",
                    "variant": "h4"
                },
                {
                    "answers": [
                        {
                            "answer": "",
                            "index": 0
                        }
                    ],
                    "index": 1,
                    "patientAnswer": "",
                    "question": "New Question",
                    "text": "Gallery description",
                    "type": "longanswer"
                },
                {
                    "answers": [
                        {
                            "answer": "",
                            "index": 0
                        }
                    ],
                    "index": 2,
                    "patientAnswer": "",
                    "question": "New Element",
                    "type": "fileupload"
                }],
                () => dispatch(setCurrentProtocol(newProtocol)));
        });
    }

    const onElementCopyConfirm = (isOpen) =>
    {
        setIsCopyOpen(false);
    }

    return (
        <div>
            <ConfirmationDialog isOpen={isDeleteOpen} callback={onPatientDeleteConfirm} />
            <PageCopyDialog isOpen={isCopyOpen} callback={onElementCopyConfirm} selectedQuestion={copyQuestion} />
            <ReactDragListView {...dragProps} >
                {protocol.pages && protocol.pages.length && protocol.pages.map(element => (
                    <div key={element.index + element.name} className="protocolPagesSelector">
                        {page.index != element.index && (
                            <Button fullWidth className="leftButton"
                                onClick={event => selectPage(element.index)} ><span className="leftButton">{element.name}</span></Button>
                        )}
                        {page.index == element.index && (
                            <Stack direction="row" spacing={3}>
                                <TextField
                                    variant="outlined"
                                    defaultValue={element.name}
                                    onBlur={event => onSetProperty('name', event.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    variant="outlined"
                                    defaultValue={element.id}
                                    onBlur={event => onSetNewId(event.target.value)}
                                    fullWidth
                                />
                                <Button className="protocolPagesHandle">
                                    <HeightIcon />
                                </Button>
                                <Button
                                    onClick={() =>
                                    {
                                        setCopyQuestion(element);
                                        setIsCopyOpen(true);
                                    }}
                                >
                                    <ContentCopyIcon />
                                </Button>
                                <Button
                                    onClick={() =>
                                    {
                                        setDeletePageID(element.index);
                                        setIsDeleteOpen(true);
                                    }}
                                >
                                    <DeleteOutlineIcon />
                                </Button>
                            </Stack>
                        )}
                    </div>
                ))}
            </ReactDragListView>
            <Button color="secondary" variant="contained" onClick={onAddNewPageClick}>Add New Gallery</Button>
        </div>
    );
}

export default withAuthentication(AdminProtocolPages);