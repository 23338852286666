import React from 'react';
import { Typography, Grid } from '@mui/material';
import LazyLoad from 'react-lazyload';


export default function ImageView(props)
{
    let image = props.image;
    let protocolId = props.protocolId;
    let url = "https://photo.markokecman.com/uploads/";

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <LazyLoad height={200} >
                <picture>
                    <source media="(min-width: 1024px)" srcSet={props.url} />
                    <source media="(min-width: 640px)" srcSet={url + protocolId + "/medium-" + image} />
                    <img src={url + protocolId + "/small-" + image} className="gallery-image-full" alt={props.caption} />
                </picture>
            </LazyLoad>
            <Typography variant="caption" style={{ textAlign: "left" }}>{props.caption}</Typography>
            <hr />
        </Grid>
    );
}

export function ImageDiagnosis(props) {
  return null;
}
