import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Checkbox, Container, IconButton, Paper, Stack, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteData, getListOrderByChildEndBeforeLimit, writeData } from '../../app/firebase';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { withAuthentication } from '../login/withAuthentication';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Footer from '../appbar/Footer';
import Spacer from '../common/Spacer';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { getUser } from '../user/UserSlice';
import { setCurrentProtocol, setProtocolCurrentPage, setProtocolCurrentQuestion } from './ProtocolSlice';

function AdminProtocols(props)
{
    const [protocolsList, setProtocolsList] = useState([]);

    const [deleteProtocol, setDeleteProtocol] = useState({});
    const [confirmDeleteProtocolDialog, setConfirmDeleteProtocolDialog] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const [searchText, setSearchText] = useState("");

    useEffect(() =>
    {
        getProtocols();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getProtocols = () =>
    {
        console.log("Loading protocols...");
        getListOrderByChildEndBeforeLimit('/protocols', setProtocols, 'name', 'z', 9999, false);
    }

    const setProtocols = (protocols) =>
    {
        console.log("Received protocols.");
        setProtocolsList(protocols);
    };

    const onProtocolClick = (protocol) =>
    {
        dispatch(setCurrentProtocol(protocol));
        dispatch(setProtocolCurrentPage(0));
        dispatch(setProtocolCurrentQuestion(-1));
        navigate('/protocol');
    }

    const onProtocolDeleteClick = (protocol) =>
    {
        setDeleteProtocol(protocol);
        setConfirmDeleteProtocolDialog(true);
    }

    const onProtocolDeleteConfirm = (isConfirmed) =>
    {
        if (isConfirmed)
        {
            if (deleteProtocol.pages !== undefined)
                for (var j = 0; j < deleteProtocol.pages.length; j++)
                {
                    deleteData('/protocols_pages/' + deleteProtocol.pages[j].id);
                };
            deleteData('/protocols/' + deleteProtocol.id, getProtocols);
            console.log("DELETED PROTOCOL: " + deleteProtocol.name);
        }

        setConfirmDeleteProtocolDialog(false);
    }

    const onSearchButtonClick = (event) =>
    {
        if (searchText !== "")
        {
            let newList = [];
            protocolsList.forEach(protocol =>
            {
                if (protocol.name.toUpperCase().indexOf(searchText.toUpperCase()) >= 0)
                    newList.push(protocol);
            });
            setProtocolsList(newList);
        }
        else
            getProtocols();
    }

    const onAddProtocol = (event) =>
    {
        let newProtocol = {
            id: uuidv4(),
            name: "A00: Novi protokol",
            published: false,
            type: "exam"
        };

        writeData('/protocols/' + newProtocol.id, newProtocol, () => onProtocolClick(newProtocol));
    }

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer height={80} />
            <ConfirmationDialog isOpen={confirmDeleteProtocolDialog} callback={onProtocolDeleteConfirm} />
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Typography align="center" variant="h5">
                        PROTOKOLI
                    </Typography>
                    <Paper className="padding20">
                        <Stack direction="row" spacing={3}>
                            <TextField
                                fullWidth
                                placeholder="Traži protokole"
                                defaultValue={searchText}
                                onChange={(event) => setSearchText(event.target.value)}
                                onKeyUp={(event) =>
                                {
                                    if (event.key === 'Enter')
                                        onSearchButtonClick(null);
                                }}
                            />
                            {user.role == "admin" && (<Button variant="contained" color="secondary" size="small" onClick={onAddProtocol}>Dodaj novi protokol</Button>)}
                        </Stack>
                    </Paper>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Protokol</TableCell>
                                    <TableCell align="right">Akcije</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {protocolsList.length > 0 && protocolsList.map(protocol =>
                                {
                                    if (protocol.name.toUpperCase().indexOf(searchText.toUpperCase()) >= 0)
                                        return (
                                            <TableRow
                                                key={protocol.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Checkbox
                                                        checked={protocol.published === true}
                                                        disabled={true}
                                                        color="secondary"
                                                        variant="outline"
                                                    />
                                                    <Button onClick={() => onProtocolClick(protocol)}>{protocol.name}</Button>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {user.role == "admin" && (
                                                        <IconButton aria-label="delete" onClick={() => onProtocolDeleteClick(protocol)}>
                                                            <DeleteIcon />
                                                        </IconButton>)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
                <Spacer />
            </Container>
            <Footer />
        </div>
    );
}

export default withAuthentication(AdminProtocols);
