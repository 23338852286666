import { Container, Grid, Typography, Skeleton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDataAsync } from '../../app/firebase';
import { setCurrentProtocol, setProtocolCurrentPage } from '../administration/ProtocolSlice';
import Spacer from '../common/Spacer';

function HomeList(props)
{

    const [protocolsList, setProtocolsList] = useState([
        <Grid key="a1" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a2" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a3" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a4" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a5" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a6" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a7" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a8" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a9" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        <Grid key="a10" item xs={12} sm={6} md={4} lg={3} xl={2.2} ><Skeleton variant="rounded" height="20vh" /></Grid>,
        
    ]);
    const dispatch = useDispatch();

    useEffect(() =>
    {
        //load PUBLIC protocol as default
        getDataAsync("/protocols/f5db6f9c-5751-4b84-9823-64b38174f874", onGetProtocol);
        // cleanup function
        return () =>
        {

        };
    }, []);

    const onGetProtocol = (protocol) =>
    {
        dispatch(setCurrentProtocol(protocol));

        let output = [];
        _.forEach(protocol.pages, value =>
        {
            output.push(
                <Grid key={value.id} item xs={12} sm={6} md={4} lg={3} xl={2.2} >
                    <div style={{
                        position: 'relative',
                        textAlign: 'center',
                        color: 'white',

                    }}>
                        <Link onClick={e => { dispatch(setProtocolCurrentPage(value.index)); }} to={value.id} >
                            <Typography variant="h4" sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textShadow: '0px 0px 5px #aaa',
                            }}>
                                {value.name}
                            </Typography>
                            <img src={value.coverimage} className="home-list-image" alt={value.name} />

                        </Link></div></Grid>,
            );
        });
        setProtocolsList(output);
    };

    return (
        <Container maxWidth="xl">
            <Spacer height={100} />
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                {protocolsList}
            </Grid>
            <Spacer height={80} />
        </Container>
    );
}

export default HomeList;
