import { TextField } from '@mui/material';
import React from 'react';

export default function LongAnswerAdmin(props)
{
    return <TextField
        multiline
        {...props.config}
        variant="outlined"
        fullWidth
        defaultValue={props.text}
        onBlur={e =>
        {
            props.setProperty('text', e.target.value);
        }}
    />;
}
