import { createTheme } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#dedede',
        },
        secondary: {
            main: '#2f2f2f',
        },
        bela: {
            main: '#ffffff',
        },
        background: {
            default: '#222',

        },
        text: {
            primary: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Montserrat, Tahoma, sans-serif',
        fontSize: 14,
        color: 'white',
        h1: {
            fontSize: '3.1rem',
        },
        h4: {
            fontSize: '1.9rem',
        },
        h3: {
            fontSize: '2.4rem',
        },
        h2: {
            fontSize: '2.8rem',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                border: 0,
                borderRadius: 3,
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                color: 'white',
                height: 48,
                padding: '0 30px',
            },
        },
    },
    components: {

    },
    shape: {
        borderRadius: 8,
    },
};


export const theme = createTheme(themeOptions);