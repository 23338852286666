import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Grid, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentProtocol } from '../../administration/ProtocolSlice';

let data = new FormData();

function FileUpload(props)
{
    const protocol = useSelector(getCurrentProtocol);
    const [newUpload, setNewUpload] = React.useState({ date: dayjs().valueOf(), files: [], institution: '', diagnosis: '' });
    const [progress, setProgress] = React.useState(0);
    const [state, setState] = React.useState('add');

    async function onSubmit(e)
    {
        e.preventDefault();
        setState('upload');
        let res = await uploadFile();
    }

    function onSelectFiles(e)
    {
        data = new FormData();
        data.append('protocol_id', protocol.id);
        let files = [];
        console.log(e);
        for (let index = 0; index < e.length; index++)
        {
            data.append('files[]', e[index]);
            files.push(e[index].name);
        }

        setNewUpload({ ...newUpload, files: files });
        setState('selected');
    }

    async function uploadFile()
    {
        var config = {
            onUploadProgress: function (progressEvent)
            {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
                console.log('Progress:-' + percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .post('https://photo.markokecman.com/upload.php', data, config)
            .then(function (res)
            {
                console.log('UPLOAD COMPLETE');
                console.log(res);
                let captions = res.data.split('~#');

                let newElements = [];
                for (let index = 0; index < newUpload.files.length; index++)
                {
                    const image = newUpload.files[index];
                    newElements.push({
                        type: 'image',
                        question: '',
                        caption: captions[index],
                        url: 'https://photo.markokecman.com/uploads/' + protocol.id + '/' + image,
                        protocolId: protocol.id,
                        image: image,
                        answers: [{ index: 0, answer: '' }],
                    });
                }

                props.addNewElements(newElements);

                setNewUpload({ date: dayjs().valueOf(), files: [], institution: '', diagnosis: '' });
                setProgress(0);
                setState('add');
            })
            .catch(function (err)
            {
                console.log(err.message);
            });
    }

    let selectedFilesNames = '';
    for (let index = 0; index < newUpload.files.length; index++)
    {
        selectedFilesNames += newUpload.files[index] + ', ';
    }

    switch (state)
    {
        case 'add': return (
            <Grid>
                <div>
                    <input style={{ display: "none" }} id="contained-button-file" multiple type="file" onChange={event => onSelectFiles(event.target.files)} />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="secondary" component="span">
                            SELECT PHOTOS
                        </Button>
                    </label>
                </div>
            </Grid>
        );

        case 'selected': return (
            <Grid>
                <Typography>
                    {selectedFilesNames}
                </Typography>
                <Button onClick={e => { setNewUpload({ ...newUpload, files: [] }); setState('add'); }}>
                    Clear selection
                </Button>
                <Button color="primary" variant="contained" onClick={onSubmit}>
                    UPLOAD PHOTOS
                </Button>
            </Grid>
        );

        case 'upload': return (
            <Grid>
                <BorderLinearProgress variant="determinate" value={progress} />
            </Grid>
        );

        default:
    }
}

export default FileUpload;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));
