import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { theme } from './app/styles';
import AdminProtocol from './features/administration/AdminProtocol';
import AdminProtocols from './features/administration/AdminProtocols';
import Gallery from './features/gallery/Gallery';
import HomeGallery from './features/gallery/HomeGallery';
import Login from './features/login/Login';


function App()
{
    return (
        <div style={{ minHeight: "100vh", backgroundColor: theme.palette.background.default }}>
            <Router>
                <Routes>
                    <Route path="/" element={<HomeGallery />} />
                    <Route path="/:galleryId" element={<Gallery />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/protocols" element={<AdminProtocols />} />
                    <Route path="/protocol" element={<AdminProtocol />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
