import { Typography } from '@mui/material';
import React from 'react';

export default function ShortAnswerView(props)
{
    return (
        <Typography variant={props.variant} textAlign="center" sx={{ whiteSpace: 'pre-wrap' }}>{props.text}</Typography>
    );
}


