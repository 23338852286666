import React from 'react';
import { MenuItem } from '@mui/material';
import ShortAnswerAdmin from './shortanswer/ShortAnswerAdmin';
import LongAnswerAdmin from './longanswer/LongAnswerAdmin';
import DateAdmin from './datetime/DateAdmin';
import MultipleChoiceAdmin from './multiplechoice/MultipleChoiceAdmin';
import CheckboxesAdmin from './checkboxes/CheckboxesAdmin';
import DropdownAdmin from './dropdown/DropdownAdmin';
import TableAdmin from './table/TableAdmin';
import VideoAdmin from './video/VideoAdmin';

import ShortAnswerView from './shortanswer/ShortAnswerView';
import LongAnswerView from './longanswer/LongAnswerView';
import DateView from './datetime/DateView';
import MultipleChoiceView from './multiplechoice/MultipleChoiceView';
import CheckboxesView from './checkboxes/CheckboxesView';
import DropdownView from './dropdown/DropdownView';
import TableView from './table/TableView';
import VideoView from './video/VideoView';

import ImageAdmin from './image/ImageAdmin';
import ImageView from './image/ImageView';
import FileUploadView from './fileupload/FileUploadView';
import FileUploadAdmin from './fileupload/FileUploadAdmin';


export const SHORT_ANSWER = 'shortanswer';
export const LONG_ANSWER = 'longanswer';
export const DATE = 'date';
export const MULTIPLE_CHOICE = 'multiplechoice';
export const CHECKBOXES = 'checkboxes';
export const DROPDOWN = 'dropdown';
export const TABLE = 'table';
export const VIDEO = 'video';
export const IMAGE = 'image';
export const FILE_UPLOAD = 'fileupload';

export const COMPONENTS_SELECT_LIST = [
    <MenuItem key={SHORT_ANSWER} value={SHORT_ANSWER}>
        Short Answer
    </MenuItem>,
    <MenuItem key={LONG_ANSWER} value={LONG_ANSWER}>
        Long Answer
    </MenuItem>,
    <MenuItem key={IMAGE} value={IMAGE}>
        Image
    </MenuItem>,
    <MenuItem key={FILE_UPLOAD} value={FILE_UPLOAD}>
        Image Upload
    </MenuItem>,
    <MenuItem key={MULTIPLE_CHOICE} value={MULTIPLE_CHOICE}>
        Multiple Choice
    </MenuItem>,
    <MenuItem key={CHECKBOXES} value={CHECKBOXES}>
        Checkboxes
    </MenuItem>,
    <MenuItem key={DROPDOWN} value={DROPDOWN}>
        Dropdown
    </MenuItem>,
    <MenuItem key={TABLE} value={TABLE}>
        Table
    </MenuItem>,
    <MenuItem key={VIDEO} value={VIDEO}>
        Video
    </MenuItem>,
    <MenuItem key={DATE} value={DATE}>
        Date
    </MenuItem>,
];

export function getComponentAdmin(config, props)
{
    switch (config.type)
    {
        case SHORT_ANSWER:
            return <ShortAnswerAdmin {...config} {...props} />;
            break;
        case LONG_ANSWER:
            return <LongAnswerAdmin {...config} {...props} />;
            break;
        case MULTIPLE_CHOICE:
            return <MultipleChoiceAdmin {...config} {...props} />;
            break;
        case DATE:
            return <DateAdmin {...config} {...props} />;
            break;
        case CHECKBOXES:
            return <CheckboxesAdmin {...config} {...props} />;
            break;
        case DROPDOWN:
            return <DropdownAdmin {...config} {...props} />;
            break;
        case TABLE:
            return <TableAdmin {...config} {...props} />;
            break;
        case VIDEO:
            return <VideoAdmin {...config} {...props} />;
            break;
        case IMAGE:
            return <ImageAdmin {...config} {...props} />;
            break;
        case FILE_UPLOAD:
            return <FileUploadAdmin {...config} {...props} />;
            break;

        default:
            return null;
            break;
    }
}

export function getComponentView(config)
{
    switch (config.type)
    {
        case SHORT_ANSWER:
            return <ShortAnswerView {...config} />;
            break;
        case LONG_ANSWER:
            return <LongAnswerView {...config} />;
            break;
        case MULTIPLE_CHOICE:
            return <MultipleChoiceView {...config} />;
            break;
        case DATE:
            return <DateView {...config} />;
            break;
        case CHECKBOXES:
            return <CheckboxesView {...config} />;
            break;
        case DROPDOWN:
            return <DropdownView {...config} />;
            break;
        case TABLE:
            return <TableView {...config} />;
            break;
        case VIDEO:
            return <VideoView {...config} />;
            break;
        case IMAGE:
            return <ImageView {...config} />;
            break;
        case FILE_UPLOAD:
            return <FileUploadView {...config} />;
            break;

        default:
            return null;
            break;
    }
}