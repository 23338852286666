import { TextField, Stack } from '@mui/material';
import React from 'react';

export default function ShortAnswerAdmin(props) {
    return <Stack>
    <TextField
        variant="outlined"
        fullWidth
        defaultValue={props.text}
        multiline
        onBlur={e => {
            props.setProperty('text', e.target.value);
        }}
        />
        <TextField
            variant="outlined"
            fullWidth
            defaultValue={props.variant}
            onBlur={e =>
            {
                props.setProperty('variant', e.target.value);
            }}
        />
    </Stack>
}
