import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getList } from '../../app/firebase';
import Footer from '../appbar/Footer';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import Spacer from '../common/Spacer';
import * as Components from '../components/Components';

function Gallery(props)
{
    const [elements, setElements] = useState([]);
    const { galleryId } = useParams();

    useEffect(() =>
    {
        getList('/protocols_pages/' + galleryId, onElementsLoad);
        // cleanup function
        return () =>
        {

        };
    }, []);

    const onElementsLoad = (elements) =>
    {
        elements.sort((a, b) => a.index - b.index);
        setElements(elements);
    };


    const drawViewComponent = question =>
    {
        const config = { ...question };
        config.answer = () =>
        {
            console.log('Ignoring answering from protocol design page.');
        };
        return Components.getComponentView(config);
    };

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer height={100} />
            <Container maxWidth="xl" disableGutters>
                {elements && elements.map(element => (
                    <span key={element.index}>
                        {drawViewComponent(element)}
                    </span>
                ))}
                <Footer showButtons/>
            </Container>
        </div>
    );
}

export default Gallery;