import React from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';

export default function ImageAdmin(props) {
    console.log(props);
  return (
      <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
          <Button onClick={e => { props.setProtocolProperty("coverimage", "https://photo.markokecman.com/uploads/" + props.protocolId + "/small-" + props.image) } }>Set Cover Image</Button>
      <Typography>Caption:</Typography>
      <TextField
        variant="outlined"
        fullWidth
        onChange={e => {
          props.setProperty('caption', e.target.value);
        }}
        value={props.caption}
      ></TextField>
      <img src={props.url} className="gallery-image-full" alt="" />
    </Grid>
  );
}
