import { Grid } from '@mui/material';
import React from 'react';
import Footer from '../appbar/Footer';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import HomeList from './HomeList';


function HomeGallery(props)
{

    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <ResponsiveAppBar />
            <HomeList />
            <Footer />
        </Grid>
    );
}

export default HomeGallery;